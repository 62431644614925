import { useContext, useEffect } from "react";
import { Context } from "context/contex";
import { SortOption, SortSelect } from "./styled";
// import bnb from "assets/bnb.png";
// import btc from "assets/btc.png";
import eth from "assets/eth.png";
import usdc from "assets/usdc.png";
// import usdt from "assets/usdt.png";

export const supportedTokens = [
  {
    id : 5,
    tokenAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", // USDC
    deciamls: 6,
  }
];

export default function SelectToken() {
  const { setSelectedToken } = useContext(Context);

  const handleSelectToken = async (value) => {
    setSelectedToken(value);
  };

  useEffect(() => {
    handleSelectToken(1);
    //eslint-disable-next-line
  }, []);

  return (
    <SortSelect
      placeholder="Select a token"
      defaultValue={1}
      dropdownStyle={{
        color: "#fff",
        backgroundColor: "#2b2a2a",
      }}
      onChange={handleSelectToken}
    >
      <SortOption value={1}>
        <img src={eth} alt="bnb" width="24" />
        <span style={{ marginLeft: "10px", color: "#fff" }}>ETH</span>
      </SortOption>
      <SortOption value={3}>
        <img src={usdc} alt="bnb" width="24" />
        <span style={{ marginLeft: "10px", color: "#fff" }}>USDC</span>
      </SortOption>
    </SortSelect>
  );
}
