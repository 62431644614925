import { createContext, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { supportedTokens } from 'components/SelectToken';
import { useWeb3React } from "@web3-react/core";
import tokenAbi from '../contract/privatesale.json';
import { getWeb3Contract } from 'hooks/contractHelper';
import { presaleAddress } from 'contract/constant';

export const Context = createContext(); 
export const ContextProvider = ({children}) => {
  const [priceLoading, setLoading] = useState(false);
  const [selectedToken, setSelectedToken] = useState(1);
  const [selectedTokenBalance, setSelectedTokenBalance] = useState('');
  const [selectedTokenPrice, setSelectedTokenPrice] = useState(1);
  const [checkAllowence, setCheckAllowence] = useState(true);
  const { account, library } = useWeb3React();

useEffect(() => {
  
    if(!selectedToken) return;
    // get user balance on select token
    const getBalance = async() => {
      if(account){

        
        try {
          if(selectedToken === 1) {
            let balance = await library.getBalance(account);
            
            setSelectedTokenBalance(ethers.utils.formatEther(balance));
            setCheckAllowence(true);
            // console.log(balance);
          } else {
            if(supportedTokens[selectedToken - 2] && typeof supportedTokens[selectedToken - 2].tokenAddress !== 'undefined'){
              let contract = await getWeb3Contract(tokenAbi , supportedTokens[selectedToken - 2].tokenAddress);
              const result = await contract.methods.balanceOf(account).call();

              const allowance = await contract.methods.allowance(account,presaleAddress).call();
              setCheckAllowence(allowance / Math.pow(10,supportedTokens[selectedToken - 2].deciamls) > 1000000 ? true : false);
              setSelectedTokenBalance(parseFloat(result.toString()) / Math.pow(10,supportedTokens[selectedToken - 2].deciamls));
            }
            else{
              setCheckAllowence(false);
              setSelectedTokenBalance(0);
            }
            // console.log(result);
          }
        } catch (error) {
          setCheckAllowence(false);
          setSelectedTokenBalance(0)
        }
      }
      else{
        
        setCheckAllowence(selectedToken === 1 ? true : false);
        setSelectedTokenBalance(0);
      }
    };
    getBalance(selectedToken);
    // eslint-disable-next-line
  },[selectedToken,account]);

  return(
    <Context.Provider
      value={{
        account,
        selectedToken,
        selectedTokenPrice,
        selectedTokenBalance,
        priceLoading,
        setSelectedToken,
        setSelectedTokenPrice,
        setLoading,
        checkAllowence,
        setCheckAllowence
      }}
    >{children}</Context.Provider>
  )
}