import { BreakLine, Container, Logo, Social, Wrapper } from "./styles";
import bitriel from "../../assets/logo.png";
// import fb from "../../assets/fb.svg";
// import linkedin from "../../assets/linkedin.svg";
// import medium from "../../assets/medium.svg";
// import twitter from "../../assets/twitter.svg";
// import website from "../../assets/website.png";
// import telegram from "assets/telegram.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Wrapper>
      <BreakLine />
      <Container>
        <Link to="/">
          <Logo alt="bitriel" src={bitriel} />
        </Link>
        <Social>
          <a
            href="https://t.me/iucncoin"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-telegram fa-font-size"></i>
          </a>
          <a
            href="https://twitter.com/IUCNCoin"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-square-twitter fa-font-size"></i>
          </a>
          <a
            href="https://instagram.com/iucncoin"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-instagram fa-font-size"></i>
          </a>
          <a
            href="https://YouTube.com/@IUCNCoinLTD"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-youtube fa-font-size"></i>
          </a>
          
          <a
            href="https://tiktok.com/@iucncoin"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-tiktok fa-font-size"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/iucncoin/"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-linkedin fa-font-size"></i>
          </a>
        </Social>
      </Container>
    </Wrapper>
  );
}
