import styled from 'styled-components';
import { Button, Card, Form, Input, Modal, Alert } from 'antd';

export const Container = styled.div`
  /* max-width: 500px; */
  width: 100%;
  padding: 0rem 0;
  p {
    font-size: 16px;
  }
  @media screen and (max-width: 500px) {
    /* padding: 0rem 10px; */
  }
`;
export const CardStyled = styled(Card)`
      border: 1px solid #fff;
    background-color: #2421219c;
    border-radius: 23px;
    max-width: 500px;
    min-height: calc(100vh - 200px);
    margin: auto;
`;
export const FormItem = styled(Form.Item)`
  display: flex;
  background: #000;
  border-radius: 16px;
  label {
    font-weight: 700;
    color: #fff;
    padding: 10px;
  }
  input {
    border-radius: 16px;
  }
`;
export const InputStyled = styled(Input)`
  width: 59%;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  background: #000;
  border-radius: 5px;
  border: none;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  &:focus {
    box-shadow: none !important;
  }
  @media screen and (max-width: 600px) {
    width: 46%;
  }
`;

export const DivTo = styled.div`
  width: 27%;
  display: 'inline';
  @media screen and (max-width: 600px) {
    width: 32%;
  }

  `;

export const BtnContribute = styled(Button)`
  position: relative;
  /* max-width: 352px; */
  width: 100%;
  height: 55px;
  border-radius: 12px;
  border: none;
  font-weight: 800;
  color: #333;
  background: #258325;
  background: #047e04; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #7cf50d,
    #047e04
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #7cf50d,
    #047e04
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: 150% 150%;
  transition: 0.5s;
  :hover {
    color: #fff;
    background: #047e04; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #63c509,
      #047e0485
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #63c509,
      #047e0485
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  :focus {
    color: #fff;
    background: #258325;
  }
  :before {
    background: #047e04; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #047e0485,
      #63c509
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #047e0485,
      #63c509
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
`;
export const BtnSelect = styled(Button)`
  width: 100%;
  height: 40px;
  font-weight: 800;
  color: #fff;
  background: ${({ active }) => (active === 'true' ? '#07d6e8f0' : '#131a35')};
  border: none;
  border-radius: 5px;
  :focus,
  :hover {
    background: #02ebff;
    color: #fff;
  }
`;
export const Subtitle = styled.p`
  /* padding: 15px 0; */
  font-size: 5.25rem !important;
  font-family: georgia !important;
  font-weight: 800;
  margin: 0;
  color: #1fc700;
`;
export const Text = styled.p`
  font-size: 16px;
  color: #f1f1f2;
  display: flex;
  margin: 0 10px;
  font-weight: 800;
`;
export const ModalStyled = styled(Modal)`
  border-radius: 16px;
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-header {
    border-radius: 16px 16px 0 0;
  }
`;
export const AlertStyled = styled(Alert)`
  background: none;
  .ant-alert-message {
    color: red;
  }
`


export const BtnConnect = styled(Button)`
    width: fit-content;
    overflow: hidden;
    height: 30px;
    color: #fff;
    background: #258325;
    border-radius: 12px;
    
    
  img {
    position: relative;
    right: 10px;
  }
  :hover {
    color: #fff;
    box-shadow: 0 0 5px 2px rgba(55, 235, 236, 0.5);
    background-color: #2583252b;
    border-color: #fff;
  }
  @media screen and (max-width: 600px) {
    width: fit-content;
    overflow: hidden;
  }
`;