import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Theme from './styles/theme';
import 'antd/dist/antd.css';
import { ContextProvider } from './context/contex';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';

function getLibrary(provider) {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 60000;
  return library;
}


ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary} >
    <ContextProvider>
      <Theme>
        <App />
      </Theme>
    </ContextProvider>
  </Web3ReactProvider>,
  document.getElementById('root')
);