import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError
} from "@web3-react/injected-connector";
import { useEffect, useState } from "react";
import { injected, walletconnect, coinbaseWallet } from "hooks/connectors";
import { Row, Col } from "antd";
import { DropdownStyled, Network } from "./styles";
import { ReactComponent as Wallet } from 'assets/wallet.svg';
import styled from "styled-components";
import {
  BtnConnect,
  MenuStyled,
  MenuItem
} from "./styles";
import ModalComponent from "components/Modal";
import metamask from 'assets/metamask.webp';
import trustwallet from 'assets/trustwallet.png';
import walletconnectsvg from 'assets/walletconnect.svg';
import { ReactComponent as Logout } from 'assets/logout.svg';
import { CHAIN_ID } from "contract/constant";
import useEagerConnect from '../../hooks/useWeb3';
import localStorage from "local-storage";




export const Connect = function () {
  const context = useWeb3React();
  const { connector, account, activate, deactivate, active, error } = context;
  const [modal, setModal] = useState(false);
  const [istrust,setIstrust] = useState(false);

  const ColItem = styled(Col)`
    /* border-radius: 20px;
    border: 2px solid #fff; */
    margin-top: 15px;
    /* margin-left:5px; */
`


  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    if (account) {
        localStorage.set('address', account);
    }
}, [account])

useEagerConnect();


  function getErrorMessage(error) {

    if (error instanceof NoEthereumProviderError) {
      if(istrust){
        console.log('trust')
        const dappUrl = window.location.href; // TODO enter your dapp URL. 
        let metamaskAppDeepLink = "https://link.trustwallet.com/open_url?coin_id=56&url=" + dappUrl;
        window.open(metamaskAppDeepLink)
    }
    else{
        console.log('metamask')
        const dappUrl = window.location.href; // TODO enter your dapp URL. 
        let metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
        window.open(metamaskAppDeepLink)

    }

    }
    if (error instanceof UnsupportedChainIdError) {
      return <span className="btn-text" onClick={(e) => switchNetwork(CHAIN_ID)}>Wrong Network</span>;
    }

    deactivate(injected);
  }

  const activating = (connection) => connection === activatingConnector;
  const connected = (connection) => connection === connector;

  const menuDropdown = (
    <MenuStyled>
      <MenuItem key='1'>
        <Row justify='space-between' onClick={(e) => {
          setActivatingConnector();
          deactivate();
          localStorage.remove('address');
        }}>
          Disconnect <Logout />
        </Row>
      </MenuItem>
    </MenuStyled>
  );

  const switchNetwork = (networkid) => {
    try {
      // @ts-ignore
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${networkid.toString(16)}` }]
      });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <React.Fragment>
      <div>

        {
          error &&
          <BtnConnect type='ghost' onClick={() => {
            setActivatingConnector();
          }}>
            <Row align='middle' justify='center'>
              <Wallet className="connect-img" />
              <span className="connect-span" style={{ marginLeft: '4px' }}>{getErrorMessage(error)}</span>
            </Row>
          </BtnConnect>
        }
        {!error &&
          <>


            {active && (connected(injected) || connected(walletconnect) || connected(coinbaseWallet)) &&
              <>
                <DropdownStyled overlay={menuDropdown}>
                  <Row align='middle' justify='center'>
                    <img src={metamask} alt='metamask' className="connect-img" />
                    <span className="connect-span" style={{marginLeft: '4px'}}>{`0x...${account.slice(-7)}`}</span>
                  </Row>
                </DropdownStyled>


              </>

            }
            {!active && (!connected(injected) || !connected(walletconnect) || !connected(coinbaseWallet)) &&
              <BtnConnect type='ghost' onClick={(e) => setModal(true)}>
                <Row align='middle' justify='center'>
                  <Wallet className="connect-img" />
                  {(activating(injected) || activating(walletconnect) || activating(coinbaseWallet)) && <span className="connect-span" style={{ marginLeft: '4px' }}>Connecting...</span>}
                  {(!activating(injected) || !activating(walletconnect) || !activating(coinbaseWallet)) && <span className="connect-span" style={{ marginLeft: '4px' }}><i className="icon-wallet mr-md-2"></i>Connect Wallet</span>}
                </Row>
              </BtnConnect>

            }
          </>
        }
      </div>
      <ModalComponent
        width={420}
        visible={modal}
        title=''
        onCancel={() => setModal(false)}
      >
        <Row>
          <ColItem span={12}>
            <Network onClick={() => {
              activate(injected);
              setModal(false);
            }}>
              <img src={metamask} alt='metamask' width='32' height='32' />
              <p>Metamask</p>
            </Network>
          </ColItem>
          <ColItem span={12}>
            <Network onClick={() => {
              activate(injected);
              setModal(false);
              setIstrust(true);
            }}>
              <img src={trustwallet} alt='trust-wallet' width='32' height='32' />
              <p>Trust Wallet</p>
            </Network>
          </ColItem>
          <ColItem span={12}>
            <Network onClick={() => {
              activate(walletconnect);
              setModal(false);
            }}>
              <img src={walletconnectsvg} alt='walletConnect' width='32' height='32' />
              <p>WalletConnect</p>
            </Network>
          </ColItem>
        </Row>
      </ModalComponent>

    </React.Fragment >
  );
};

export default Connect;