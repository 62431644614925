import { Collapse, Button } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 700px;
  min-height: calc(100vh - 120px);
  margin: 60px auto 30px auto;
  padding: 0rem 0;
  @media screen and (max-width: 600px) {
    padding: 0rem 10px;
    width: 372px;
    margin: 40px auto 0px auto;
  }
`
export const CardStyled = styled.div`
  max-width: 600px;
  max-height: 650px;
  margin: auto;
  background-color: #00000000;
  border-radius: 16px; 
  padding: 15px;
  border: 1px solid #000000;
  
`
export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  text-align:center ;
`
export const Text = styled.p`
  margin: 0;
  text-align: center;
`
export const CollapseStyled = styled(Collapse)`
  align-items: center;
  background-color: #0D1225;
  border: none;
  border-radius: 16px;
  margin-bottom: 20px;
  .ant-collapse-item {
    border: none;
  }
  .ant-collapse-item > .ant-collapse-header {
    color: #fff;
    height: 70px;
    line-height: 45px;
  }
  .ant-collapse-content {
    background-color: #0D1225;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    color: #fff;
    border: none;
    border-radius: 16px;
  }
`
export const BtnClaim = styled(Button)`
  border-radius: 16px;
  width: 110px;
  padding: 13px;
  line-height: 15px;
  height: 40px;
  color: #fff;
  font-weight: 500;
  border: none;
  background: linear-gradient(125deg,#1fb956,#90eeaa) ;
  :hover {
    background: linear-gradient(125deg,#90eeaa,#1fb956) ;
  }
`

export const HeadStyle = styled.h5`
  margin: 0px;
    font-size: 20px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 1.334;
    color:#258325;
    border-bottom: 6px solid;
    padding-bottom: 8px;
`
export const DivP = styled.div`
     box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top:10px ;
`
export const InfoP = styled.p`
    margin: 0px 0px 0.35em;
    font-weight: 400;
    line-height: 1.5;
    color: #258325;
`

export const AddMetaBtn = styled.button`
  border-radius: 16px;
  width: fit-content;
  padding: 13px;
  line-height: 10px;
  height: 40px;
  color: #fff;
  font-weight: 400;
  background: linear-gradient(125deg,#1fb956,#90eeaa);
  font-size: 11px ;
  border: none;
  cursor: pointer;
  :hover {
    background: linear-gradient(125deg,#90eeaa,#1fb956);
  }
`