import { Button, Dropdown, Menu } from "antd";
import styled from "styled-components";

export const BtnConnect = styled(Button)`
    width: fit-content;
    overflow: hidden;
    height: 40px;
    color: #fff;
    background: #258325;
    border-radius: 12px;
    font-weight: bold;
    margin-right: 20px;
    padding: 8px;
  img {
    position: relative;
    right: 10px;
  }
  :hover {
    color: #fff;
    box-shadow: 0 0 5px 2px rgba(55, 235, 236, 0.5);
    background-color: #2583252b;
    border-color: #fff;
  }
  :focus {
    color: #fff;
    box-shadow: 0 0 5px 2px rgba(55, 235, 236, 0.5);
    background-color: #2583252b;
    border-color: #fff;
  }
  @media screen and (max-width: 600px) {
    width: 122px;
    padding: 8px;
    overflow: hidden;
    margin-right: 0px;
  }
`;
export const DropdownStyled = styled(Dropdown)`
    width: 150px;
    overflow: hidden;
    height: 47px;
    color: #fff;
    background: #258325;
    border-radius: 12px;
    font-weight: bold;
    margin-right: 20px;
    padding: 15px;
    line-height: 5px;

  @media screen and (max-width: 480px) {
    width: 120px;
    padding: 0 10px;
    overflow: hidden;
  }
`;
export const Network = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
  padding: 1.8rem 0;
  border-radius: 20px;
  border: 1px solid #cdb2b2;
  margin: 10px;
  :hover {
    opacity: .6;
  }
  p {
    font-weight: 600;
    margin-top: 10px;
  }
`;
export const MenuStyled = styled(Menu)`
  background: rgba(20,25,53,100);
  color: #fff;
  border-radius: 10px;
`
export const MenuItem = styled(Menu.Item)`
  color: #fff;
  font-weight: 600;
  :hover {
    background: none;
    opacity: .7;
  }
`