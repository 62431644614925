import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import backImg from '../assets/background.jpg'

const GlobalStyles = createGlobalStyle`
  ${normalize};

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  .text-white{
    color: #fff !important ;
  }

  .text-dark{
    color: #000000 !important ;
  }
  body {
    font-family: ${(props) => props.theme.fonts.main};
    font-size: 16px;
    /* background: ${({ theme }) => theme.colors.background1}; */
    /* color: ${(props) => props.theme.colors.primary1}; */
    color: #00a100;
    cursor: default;
    /* background: url(${backImg}) #191919; */
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    
  }
  h1,h2,h3,h4,h5,h6,button {
    font-family: ${(props) => props.theme.fonts.title};
  }
  a {
    text-decoration: none;
  }
  li{
    list-style: none;
  }
  .ant-modal-content,
  .ant-modal-header,
  .ant-popover-inner,
  .ant-popover-inner-content {
    background: #2b2a2a;
    color: #fff;
  }
  .ant-modal-title {
    color: #f1f1f5;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
  }
  .ant-select-item-option:hover,
  .ant-select-item-option-active:not(.ant-select-item-option-disabled),
  .ant-select-item .ant-select-item-option .custom-option .ant-select-item-option-active   {
    background: #283871;
  }
  .ant-select-arrow {
    color: #fff;
  }
  .ant-modal-close-x {
    color: #f1f1f2;
  }
  .home-title{
    font-size: 34px !important;
    font-weight: bold;
    margin-bottom: 0px;
    color: #258325;
  }
  .home-sub-title{
    font-size: 20px !important;
    margin-bottom: 0px;
  }

  .ant-form-item .ant-select, .ant-form-item .ant-cascader-picker {
    width: 28%;
  }

  .connect-img{
      width : 20px;
    }
    .connect-span{
      font-size : 15px;
    }
    


  @media screen and (max-width: 600px) {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      padding-right: 1px !important;
    }
    .ant-form-item .ant-select, .ant-form-item .ant-cascader-picker {
        width: 36%;
      }
  }


    .connect-img{
      width : 10px;
    }
    .connect-span{
      font-size : 12px;
    }

    .c-style{
      font-size : 16px;
      margin-left: 8px; 
    }

    .home-sub-title{ 
      font-size: 19px !important;
    }
  
`;

export default GlobalStyles;
