import { Link } from 'react-router-dom';
import { Drawer } from 'antd';
import styled from 'styled-components/macro';

export const Wrapper = styled.header`
  width: 100%;
  padding: 20px 0px;
  border-bottom: 2px solid rgba(0, 32, 70, 0.75);
  background: rgba(0, 32, 70, 0.75) ;
`;
export const Container = styled.div`
  max-width: 1216px;
  height: 50px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const HeaderItems = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  /* grid-gap: 40px; */
  align-items: center;
`;
export const Item = styled(Link)`
  /* display: block;
  font-size: 16px; 
  font-weight: ${({ active }) => (active === 'true' ? '700' : '500')};
  color: ${({ active }) => (active === 'true' ? '#fff' : '#fff')};
  background: ${({ active }) => (active === 'true' ? 'linear-gradient(90deg,#0545b5,#00f9ff)' : 'none')};
  width: ${({ mobile }) => mobile === 'true' ? '100%' : '120px'};
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 8px;
  background-size: 150% 150%;
  transition: 0.3s;
  :hover {
    color: #00f9ff;
  } */

  display: block;
  font-size: 16px; 
  font-weight: ${({ active }) => (active === 'true' ? '700' : '500')};
  color: ${({ active }) => (active === 'true' ? '#fff' : '#fff')};
  background: ${({ active }) => (active === 'true' ? '#258325' : 'none')};
  width: ${({ mobile }) => mobile === 'true' ? '100%' : '120px'};
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 8px;
  background-size: 150% 150%;
  transition: 0.3s;
  :hover {
    color: #fff;
    box-shadow: 0 0 5px 2px rgba(55, 235, 236, 0.5);
    background-color: #2583252b;
    border-color: #fff;
  }
`;
export const Logo = styled.img`
    height: 90px;
    /* width: 200px; */
    @media screen and (max-width: 500px) {
      height: 61px;
    width: 81px;
  }
`;
export const MenuIcon = styled.img`
  cursor: pointer;
`;
export const DrawerStyled = styled(Drawer)`
  .ant-drawer-body {
    background: ${({ theme }) => theme.colors.background1};
  }
  .anticon {
    display: none;
  }
`;

