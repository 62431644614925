import { useEffect, useState } from "react";
import { Empty, Row } from "antd";
import {
  CardStyled,
  Container,
  HeadStyle,
  DivP,
  InfoP,
  AddMetaBtn,
} from "./styled";
import Spinner from "react-spinkit";
import { useWeb3React } from "@web3-react/core";
import { getWeb3Contract } from "hooks/contractHelper";
import presaleAbi from "../../contract/presale.json";
import metamask from "assets/metamask.webp";
import { presaleAddress, tokenAddress } from "contract/constant";


export default function Order() {
  const context = useWeb3React();
  const { account } = context;
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);



  useEffect(() => {
    const getOrder = async () => {
      setLoading(true);
      try {
        if (account) {
          console.log(account);
          let contract = getWeb3Contract(presaleAbi, presaleAddress);

          const data_array = await contract.methods.investorOrderIds(account).call();

          Promise.all(data_array.map(async (index) => {
            let getdata = await contract.methods.orders(index).call();
            return getdata;
          })).then((result) => {
            setOrders(result);
          });
        } else {
          setOrders([]);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    getOrder();
  }, [account]);

  return (
    <Container>

      <CardStyled>
        <DivP>
          <HeadStyle>Token Purchased Info</HeadStyle>
          <InfoP>
            <AddMetaBtn onClick={(e) => handleAddToken(e)}>
              Add to metamask
              <img
                style={{ paddingLeft: "5px" }}
                src={metamask}
                alt="metamask"
                width="17"
              />
            </AddMetaBtn>
          </InfoP>
        </DivP>
        <DivP>
          <InfoP>Account Address</InfoP>
          <InfoP className="text-dark">
            {account
              ? `${account.substring(0, 6)}...${account.substring(
                account.length - 4
              )}`
              : " - "}
          </InfoP>
        </DivP>
        <DivP>
          <InfoP>Total Amount</InfoP>
          <InfoP>Purchase On</InfoP>
        </DivP>
        <hr/>
        {loading ? (
          <Row justify="center">
            <Spinner name="circle" color="#fac66b" />
          </Row>
        ) : orders.length === 0 ? (
          <Empty description={<span>No Activity Yet</span>} />
        ) : (
          orders.map(
            (i) => (
              // <CollapseStyled key={i.order_id}>
              // <Collapse.Panel header={`${i.order_id}`} bordered={false} key={i.order_id}>
              <>

                <DivP >

                  <InfoP className="text-dark">
                    {new Intl.NumberFormat('en-US', { maximumSignificantDigits: 6 }).format(i.totalToken / Math.pow(10, 18))} IUCN
                  </InfoP>
                  <InfoP >
                    <InfoP className="text-dark">{new Date(i.time * 1000).toDateString()}</InfoP>
                  </InfoP>

                </DivP>

              </>
            )
            // </Collapse.Panel>
            // </CollapseStyled>
          )
        )}
      </CardStyled>
    </Container>
  );
}


export const handleAddToken = async () => {
  try {
    // @ts-ignore
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          name: "IUCN Coin",
          address: tokenAddress, // The address that the token is at.
          symbol: "IUCN", // A ticker symbol or shorthand, up to 5 chars.
          decimals: 18, // The number of decimals in the token
          image:
            "https://etherscan.io/token/images/iucncoin_32.png", // A string url of the token logo
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
};
