import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { ethers } from "ethers";
import Web3 from "web3";
import { RPC_URL , CHAIN_ID } from "contract/constant";





export const infura_Id = "84842078b09946638c03157f83405213";

export const getRpcUrl = () => {
  return {
    [CHAIN_ID] : RPC_URL
  }[CHAIN_ID]

}

export const getWeb3 = () => {
  return new Web3(getRpcUrl());
}




export const injected = new InjectedConnector({
  supportedChainIds: [CHAIN_ID]
})

export const walletconnect = new WalletConnectConnector({
  rpc: {  43114 : RPC_URL },
  qrcode: true,
  infuraId: infura_Id,
});

export const coinbaseWallet = new WalletLinkConnector({

  url: `https://mainnet.infura.io/v3/84842078b09946638c03157f83405213`,
  appName: "Lauchpad App",
  supportedChainIds: [CHAIN_ID],

});

export const simpleRpcProvider = new ethers.providers.StaticJsonRpcProvider(getRpcUrl());
// export const web3 = new Web3(getRpcUrl());
